import logo from "./header/logo";

export default editor => {
    editor.DomComponents.addType('header', {
        // Make the editor understand when to bind `my-input-type`
        isComponent: el => el.tagName === 'SECTION',

        // Model definition
        model: {
            // Default properties
            defaults: {
                tagName: 'section',
                droppable: false,
                draggable: false,
                removable: false,
                copyable: false,
                attributes: {
                    class: 'section header'
                },
                components: [
                    logo
                ]
            }
        }
    });
}
