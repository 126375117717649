export default editor => {
    editor.DomComponents.addType('paragraph', {
        // Make the editor understand when to bind `my-input-type`
        isComponent: el => {
            if (el.classList && el.classList.contains('et-paragraph')) {
                return { type: 'paragraph' }
            }
            return false
        },

        // Model definition
        model: {
            // Default properties
            defaults: {
                tagName: 'p',
                draggable: '[data-gjs-type=column]',
                attributes: {
                    class: 'et-paragraph'
                },
                highlightable: false,
                hoverable: false,
                components: [
                    {
                        type: 'text',
                        name: 'Text',
                        tagName: 'span',
                        draggable: false,
                        editable: true,
                        removable: false,
                        content: `Lorem ipsum dolor....`
                    }
                ]
            }
        }
    });
}
