export default editor => {
    editor.DomComponents.addType('body', {
        // Make the editor understand when to bind `my-input-type`
        isComponent: el => el.tagName === 'SECTION',

        // Model definition
        model: {
            // Default properties
            defaults: {
                name: 'Inhalte',
                tagName: 'section',
                droppable: true,
                draggable: false,
                removable: false,
                copyable: false,
                attributes: {
                    class: 'section et-body'
                },
                components: [
                    {type:"page"}
                ]
            }
        }
    });
}
