<template>
  <div>
    <h3 class="text-gray-700 text-3xl font-medium">Landingpage</h3>
    <GrapesJS></GrapesJS>
  </div>
</template>

<script>
import GrapesJS from '@/components/GrapesJs/GrapesJS'

export default {
  name: "Landingpage",
  components: {
    GrapesJS
  },
  data(){
    return {

    }
  }
}
</script>

<style scoped>

</style>
