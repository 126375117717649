export default editor => {
    editor.DomComponents.addType('page', {
        // Make the editor understand when to bind `my-input-type`
        isComponent: el => el.tagName === 'SECTION',

        // Model definition
        model: {
            // Default properties
            defaults: {
                name: 'Formularseite',
                draggable: '[data-gjs-type=body]',
                tagName: 'div',
                attributes: {
                    class: 'et-page'
                },
                components: [
                    {type:"columns"}
                ]
            }
        }
    });
}
